import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ReactElement, ReactNode, useEffect } from "react";
import type { NextPage } from "next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Head from "next/head";
import { AnalyticsProvider } from "@context/analytics";
import "@components/styles.collaboration.css";
import { trpc } from "@utils/trpc";
import { Toaster as RHTToaster } from "react-hot-toast";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
} from "@clerk/nextjs";
import { useRouter } from "next/router";
import { ModalProvider } from "@context/ModalContext";

const publicPages = ["/viewer/[id]"];

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({
  Component,
  //@ts-ignore
  pageProps: { ...pageProps },
}: AppPropsWithLayout) {
  // only call if window is defined. this should run on every page
  const router = useRouter();
  NProgress.configure({ showSpinner: false });

  useEffect(() => {
    const handleStart = (url: string) => {
      NProgress.start();
    };
    const handleStop = (url: string) => {
      NProgress.done();
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
    };
  }, [router]);
  useEffect(() => {
    if (typeof window !== undefined) {
      (function (w, r) {
        // @ts-ignore
        w._rwq = r;
        // @ts-ignore
        w[r] =
          // @ts-ignore
          w[r] ||
          function () {
            // @ts-ignore
            (w[r].q = w[r].q || []).push(arguments);
          };
      })(window, "rewardful");

      //   <Script
      //   src="https://r.wdfl.co/rw.js"
      //   data-rewardful="472f79"
      // ></Script>
      const script = document.createElement("script");
      script.src = "https://r.wdfl.co/rw.js";
      script.setAttribute("data-rewardful", "472f79");
      document.body.appendChild(script);
    }
  }, []);
  const { pathname } = useRouter();
  const isPublicPage = publicPages.includes(pathname);
  // Use the layout defined at the page level, if available

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <ClerkProvider {...pageProps}>
      <AnalyticsProvider writeKey="aZ1IlbsMUANzBOtSpJJELZNy5BjY3cQj">
        <ModalProvider>
          <Head>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
            />
            <meta
              name="description"
              content="Moonbeam makes long form writing 10x easier for you."
            />
            <meta name="theme-color" content="#000" />
            <title>Moonbeam</title>
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="apple-touch-icon" href="/apple-icon.png"></link>
          </Head>
          {isPublicPage ? (
            getLayout(<Component {...pageProps} />)
          ) : (
            <>
              {" "}
              <SignedIn>{getLayout(<Component {...pageProps} />)}</SignedIn>
              <ToastContainer autoClose={1000} />
              <RHTToaster />
              <SignedOut>
                <RedirectToSignIn
                  redirectUrl={
                    typeof window !== "undefined" ? window.location.href : ""
                  }
                />
              </SignedOut>
            </>
          )}
        </ModalProvider>
      </AnalyticsProvider>
    </ClerkProvider>
  );
}

export default trpc.withTRPC(App);
