import { ModalContext } from "@context/ModalContext";
import { useOutsideClick } from "@hooks/useOutsideClick";
import React, { useContext, useRef } from "react";
import ReactDOM from "react-dom";

export const UniversalModal = () => {
  let { modalContent, handleModal, modal } = useContext(ModalContext);
  const outsideClickRef = useRef(null);

  useOutsideClick(outsideClickRef, () => handleModal());
  if (modal) {
    return ReactDOM.createPortal(
      <div className="fixed top-0 left-0 h-screen w-full flex items-center justify-center z-[200] backdrop-blur-sm bg-black/20">
        <div ref={outsideClickRef}>{modalContent}</div>
      </div>,
      //@ts-ignore
      document.querySelector("#modal-root")
    );
  } else return null;
};
