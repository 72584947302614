import { UniversalModal } from "@components/Helpers/UniversalModal/UniversalModal";
import { useModal } from "@hooks/useModal";
import React from "react";

let ModalContext: any;
let { Provider } = (ModalContext = React.createContext<any>(null));

let ModalProvider = ({ children }: any) => {
  let { modal, handleModal, modalContent } = useModal();

  return (
    <Provider value={{ modal, handleModal, modalContent }}>
      <UniversalModal />
      <div id="modal-root">{children}</div>
    </Provider>
  );
};

export { ModalContext, ModalProvider };
